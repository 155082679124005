import React from "react";
import { Link } from "react-router-dom";
import { notify } from "../../../utils/Title";

export default function BangXepHangThuHoach({ bxhThuHoach, info }) {
  return (
    <div className="bxh_list">
      {bxhThuHoach.map((user, index) => (
        <div className="bxh_one" key={index}>
          <div className="bxh_user_image">
            <img
              src={"/images/farm/bxh/" + (index + 1) + ".gif"}
              alt="user_image"
            />
          </div>
          <div className="bxh_user_detail">
            <div className="bxh_username">
              <b>{"#" + (index + 1) + " " + user.username}</b>
            </div>
            <div className="bxh_quanty">
              {"Đã thu hoạch: " + user.thu_hoach_quanty + " cây"}
            </div>
            <div className="bxh_quanty">
              {"Tiền nông trại: " + user.vnd.toLocaleString("vi") + " VNĐ"}
            </div>
            <div className="bxh_quanty">
              {"Tiền mặt: " + user.xu.toLocaleString("vi") + " VNĐ"}
            </div>
            <div className="bxh_quanty">
              <span
                style={{
                  color: "#ad4105",
                }}
              >
                Thăm nông trại:{" "}
              </span>
              <Link
                className="one_app_child_content"
                to={"/farm/view/" + user.username}
                onClick={() => {
                  notify(true, "Bạn đang xem nông trại của " + user.username);
                }}
              >
                Vào xem
              </Link>
            </div>
            {index === 0 ? (
              <div className="bxh_quanty" style={{ color: "#28a745" }}>
                {"Giải thưởng: 100K thẻ cào / tiền mặt"}
              </div>
            ) : index === 1 ? (
              <div className="bxh_quanty" style={{ color: "#28a745" }}>
                {"Giải thưởng: 50K thẻ cào / tiền mặt"}
              </div>
            ) : index === 2 ? (
              <div className="bxh_quanty" style={{ color: "#28a745" }}>
                {"Giải thưởng: 30K thẻ cào / tiền mặt"}
              </div>
            ) : index > 2 && index < 10 ? (
              <div className="bxh_quanty" style={{ color: "#28a745" }}>
                {"Giải thưởng: 10K thẻ cào / tiền mặt"}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ))}
      <div
        style={{
          lineHeight: "24px",
          marginTop: 10,
        }}
      >
        <div className="bxh_one">
          <div className="bxh_user_image">
            <img
              src={"/images/farm/user/" + info.user_image + ".gif"}
              alt="user_image"
            />
          </div>
          <div
            className="bxh_user_detail"
            style={{
              flex: 1,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <b>Thành tích của bạn</b>
            </div>
            <div className="bxh_username">
              <b>{"Tài khoản: " + info.username}</b>
            </div>
            <div className="bxh_quanty">
              {"Đã thu hoạch: " + info.thu_hoach_quanty + " cây"}
            </div>
            <div className="bxh_quanty">
              {"Tiền nông trại: " + info.vnd.toLocaleString("vi") + " VNĐ"}
            </div>
            <div className="bxh_quanty">
              {"Tiền mặt: " + info.xu.toLocaleString("vi") + " VNĐ"}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          textAlign: "center",
          lineHeight: "24px",
          marginTop: "10px",
        }}
      >
        Sau khi chương trình kết thúc{" "}
        <span style={{ color: "rgb(40, 167, 69)" }}>
          (Tức sau ngày 03/04/2025)
        </span>{" "}
        Bạn liên hệ mình qua Zalo hoặc Facebook để nhận giải thưởng nhé.
      </div>

      <div
        style={{
          textAlign: "center",
          lineHeight: "24px",
          marginTop: 10,
        }}
      >
        Tham gia nhóm Facebook:{" "}
        <Link
          to={"https://www.facebook.com/share/g/1EvB1DHpqr/"}
          target="_blank"
        >
          Tham gia
        </Link>
      </div>
      <div
        style={{
          textAlign: "center",
          lineHeight: "24px",
          marginTop: 10,
        }}
      >
        Tham gia nhóm Zalo:{" "}
        <Link to={"https://zalo.me/g/andlwj207"} target="_blank">
          Tham gia
        </Link>
      </div>
    </div>
  );
}
