import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import { ApiLink, notify, Title } from "../../utils/Title";
import { Link, useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import InputUsernameLayout from "../../components/Layouts/BasicLayout/InputUsernameLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
export default function EventPage() {
  const [show, setShow] = useState(1);
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let navigate = useNavigate();
  let [usernameInput, setUsernameInput] = useState("");
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);

  const eventList = [
    {
      event_id: 1,
      event_logo: "/images/image/gofarm.webp",
      event_title: "SỰ KIỆN ĐUA TOP THU HOẠCH - NÔNG TRẠI VUI VẺ GOFARM",
      event_title_short: "EVENT ĐUA TOP THU HOẠCH GOFARM",
      event_time: "13/03/2025 - 03/04/2025",
      event_prize: "Thẻ cào, tiền mặt",
      event_admin: (
        <Link
          style={{ color: "#007bff", textDecoration: "none" }}
          to={"https://www.facebook.com/n.ducngoc.233"}
          target="_blank"
        >
          Đức Ngọc
        </Link>
      ),
      event_people: "Tất cả mọi người",
      event_content: (
        <div className="app_note">
          <p style={{ textAlign: "center" }}>
            <strong>SỰ KIỆN ĐUA TOP THU HOẠCH - NÔNG TRẠI VUI VẺ GOFARM</strong>
          </p>
          <p>
            <strong>Thời gian sự kiện:</strong>
            <strong> 13/03/2025 - 03/04/2025</strong>
          </p>
          <p>
            <p style={{ color: "#008000" }}>
              <strong>Mô tả sự kiện:</strong>
            </p>
            <span style={{ fontWeight: 400 }}>Hãy sẵn sàng để tham gia </span>
            <strong>Sự kiện Đua Top Thu Hoạch</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              cực kỳ hấp dẫn, nơi bạn sẽ tranh tài với những người chơi khác để
              đạt được thành tích thu hoạch cao nhất trong suốt 21 ngày diễn ra
              sự kiện! Đây là cơ hội tuyệt vời để bạn thể hiện kỹ năng trồng
              trọt và giành được những phần thưởng giá trị.
            </span>
          </p>
          <p>
            <span style={{ color: "#008000" }}>
              <strong>Cách tham gia sự kiện:</strong>
            </span>
          </p>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <strong>
                Tạo tài khoản và Đăng nhập vào mục "Nông trại kiếm tiền"
              </strong>{" "}
              <span style={{ fontWeight: 400 }}>
                trong thời gian diễn ra sự kiện. Sau khi tạo tài khoản mới, bạn
                sẽ được tặng 5.000đ vào tiền nông trại dùng để mua hạt giống.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Mua hạt giống tại mục </span>
              <strong>"Cửa hàng"</strong>{" "}
              <span style={{ fontWeight: 400 }}>
                và chọn ô đất để gieo. Mỗi cây trồng sẽ có thời gian thu hoạch
                khác nhau. Bạn cần có chiến thuật gieo trồng hợp lý để đảm bảo
                thu hoạch đúng thời gian và tối ưu nhất.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>Bắt đầu thu hoạch</strong>{" "}
              <span style={{ fontWeight: 400 }}>
                các loại cây trồng trong nông trại của bạn.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Mỗi cây trồng thu hoạch sẽ được tích 01 điểm và được tính vào
                bảng xếp hạng.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>Top 10 người chơi</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                đạt điểm cao nhất trong suốt thời gian sự kiện sẽ nhận được phần
                thưởng thẻ cào hoặc nhận tiền mặt (Qua chuyển khoản, ví điện
                tử).
              </span>
            </li>
          </ol>
          <p>
            <span style={{ color: "#008000" }}>
              <strong>Phần thưởng cho Top 10 người chơi:</strong>
            </span>
          </p>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <strong>🏆 01 Giải Nhất:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 100K cho người chơi
                thu hoạch nhiều nhất.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>🥈 01 Giải Nhì:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 50K cho người chơi thu
                hoạch nhiều thứ 2.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>🥉 01 Giải Ba:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 30K cho người chơi thu
                hoạch nhiều thứ 3.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>🎖️ 07 Giải Khuyến Khích:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 10K cho người chơi thu
                hoạch nhiều tiếp theo.
              </span>
            </li>
          </ol>
          <p>
            <strong>Hình thức trao giải:</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              Sau khi chương trình kết thúc, những bạn nằm trong danh sách Top
              bảng xếp hạng liên hệ mình thông qua Zalo hoặc Facebook để nhận
              giải nhé.
            </span>
          </p>
          <p>
            <span style={{ color: "#ff0000" }}>
              <strong>Lưu ý:</strong>
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Mỗi loại cây sẽ có thời gian thu hoạch khác nhau, mỗi cây thu
                hoạch sẽ nhận được 01 điểm.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Bảng xếp hạng sẽ được cập nhật liên tục trong mục
              </span>{" "}
              <strong>"Nông trại kiếm tiền"</strong>{" "}
              <span style={{ fontWeight: 400 }}>
                để bạn có thể tiện theo dõi.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Sau khi thu hoạch xong, bạn có thể bán nông sản để nhận được
                tiền nông trại. Tiền nông trại có thể dùng để rút tiền mặt, đổi
                thẻ cào (Đã cập nhật tỉ lệ quy đổi tiền nông trại).
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Những bạn sử dụng nhiều tài khoản để tham gia nếu nằm trong Top
                xếp hạng sẽ không được nhận giải.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ color: "#008000" }}>
              <strong>Các kênh hỗ trợ:</strong>
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Kênh YouTube:{" "}
                <Link
                  title="Đức Ngọc Kiếm Tiền"
                  to="https://www.youtube.com/c/kiemthecao/videos"
                  target="_blank"
                >
                  Đức Ngọc Kiếm Tiền
                </Link>
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Zalo:{" "}
                <span
                  onClick={() => {
                    alert("SĐT Zalo: 0378544081 - Đức Ngọc");
                  }}
                  style={{ color: "#007bff", cursor: "pointer" }}
                >
                  Đức Ngọc
                </span>
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Facebook cá nhân:{" "}
                <Link
                  style={{ color: "#007bff", textDecoration: "none" }}
                  to={"https://www.facebook.com/n.ducngoc.233"}
                  target="_blank"
                >
                  Đức Ngọc
                </Link>
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Fanpage:{" "}
                <Link
                  style={{ color: "#007bff", textDecoration: "none" }}
                  to={"https://www.facebook.com/kiemthecaocom"}
                  target="_blank"
                >
                  Kiếm Thẻ Cào
                </Link>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "center" }}>
            <strong>
              Hãy tham gia ngay để trở thành người chiến thắng và nhận những
              phần thưởng hấp dẫn. Chúc mọi người tham gia vui vẻ!
            </strong>
          </p>
        </div>
      ),
    },
    {
      event_id: 1,
      event_logo: "/images/image/gofish.png",
      event_title: "SỰ KIỆN ĐUA TOP CÂU CÁ - CAO THỦ CÂU CÁ",
      event_title_short: "EVENT ĐUA TOP CÂU CÁ GOFARM",
      event_time: "13/03/2025 - 03/04/2025",
      event_prize: "Thẻ cào, tiền mặt",
      event_admin: (
        <Link
          style={{ color: "#007bff", textDecoration: "none" }}
          to={"https://www.facebook.com/n.ducngoc.233"}
          target="_blank"
        >
          Đức Ngọc
        </Link>
      ),
      event_people: "Tất cả mọi người",
      event_content: (
        <div className="app_note">
          <p style={{ textAlign: "center" }}>
            <strong>SỰ KIỆN ĐUA TOP CÂU CÁ - CAO THỦ CÂU CÁ</strong>
          </p>
          <p>
            <strong>Thời gian sự kiện:</strong>
            <strong> 13/03/2025 - 03/04/2025</strong>
          </p>
          <p>
            <p style={{ color: "#008000" }}>
              <strong>Mô tả sự kiện:</strong>
            </p>
            <span style={{ fontWeight: 400 }}>Sẵn sàng tham gia </span>
            <strong>Sự kiện Đua Top Câu Cá</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              đầy kịch tính để khẳng định kỹ năng câu cá đỉnh cao của bạn! Không
              chỉ là câu cá, đây còn là cuộc chiến của sự kiên nhẫn, chiến thuật
              và đam mê, nơi bạn sẽ tranh tài với những người chơi khác để đạt
              thành tích cao nhất trong suốt 21 ngày diễn ra sự kiện và giành
              được những phần thưởng giá trị.
            </span>
          </p>
          <p>
            <span style={{ color: "#008000" }}>
              <strong>Cách tham gia sự kiện:</strong>
            </span>
          </p>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <strong>Tạo tài khoản và Đăng nhập vào mục "Câu cá"</strong>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Mua cần câu và mồi câu tại mục{" "}
              </span>
              <strong>"Cửa hàng"</strong>{" "}
              <span style={{ fontWeight: 400 }}>
                và vào khu vực câu cá để câu. Mỗi con cá câu được sẽ có cân nặng
                khác nhau. Cân nặng càng cao, bạn leo TOP càng nhanh.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Cân nặng của cá sẽ được tính vào bảng xếp hạng. Người có tổng
                cân nặng cao hơn sẽ được xếp trên. Đừng quên tham gia trồng cây,
                nuôi vật nuôi kiếm thêm thêm tiền nông trại để mua mồi câu nhé!
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>Top 10 người chơi</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                đạt điểm tổng cân nặng cao nhất trong suốt thời gian sự kiện sẽ
                nhận được phần thưởng thẻ cào hoặc nhận tiền mặt (Qua chuyển
                khoản, ví điện tử).
              </span>
            </li>
          </ol>
          <p>
            <span style={{ color: "#008000" }}>
              <strong>Phần thưởng cho Top 10 người chơi:</strong>
            </span>
          </p>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <strong>🏆 01 Giải Nhất:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 100K cho người chơi có
                tổng cân nặng cao nhất.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>🥈 01 Giải Nhì:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 50K cho người chơi có
                tổng cân nặng cao thứ 2.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>🥉 01 Giải Ba:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 30K cho người chơi có
                tổng cân nặng cao thứ 3.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <strong>🎖️ 07 Giải Khuyến Khích:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Thẻ cào điện thoại hoặc tiền mặt mệnh giá 10K cho người chơi có
                tổng cân nặng cao tiếp theo.
              </span>
            </li>
          </ol>
          <p>
            <strong>Hình thức trao giải:</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              Sau khi chương trình kết thúc, những bạn nằm trong danh sách Top
              bảng xếp hạng liên hệ mình thông qua Zalo hoặc Facebook để nhận
              giải nhé.
            </span>
          </p>
          <p>
            <span style={{ color: "#ff0000" }}>
              <strong>Lưu ý:</strong>
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Mỗi con cá sẽ có cân nặng khác nhau, mỗi 1KG sẽ nhận được 01
                điểm.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Bảng xếp hạng sẽ được cập nhật liên tục trong mục
              </span>{" "}
              <strong>"Câu cá kiếm tiền"</strong>{" "}
              <span style={{ fontWeight: 400 }}>
                để bạn có thể tiện theo dõi.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Sau khi câu được cá, bạn có thể cá cho <b>Anh Ba Khía</b> trong
                khu câu cá để nhận được tiền nông trại. Tiền nông trại có thể
                dùng để rút tiền mặt, đổi thẻ cào.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Những bạn sử dụng nhiều tài khoản để tham gia nếu nằm trong Top
                xếp hạng sẽ không được nhận giải.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ color: "#008000" }}>
              <strong>Các kênh hỗ trợ:</strong>
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Kênh YouTube:{" "}
                <Link
                  title="Đức Ngọc Kiếm Tiền"
                  to="https://www.youtube.com/c/kiemthecao/videos"
                  target="_blank"
                >
                  Đức Ngọc Kiếm Tiền
                </Link>
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Zalo:{" "}
                <span
                  onClick={() => {
                    alert("SĐT Zalo: 0378544081 - Đức Ngọc");
                  }}
                  style={{ color: "#007bff", cursor: "pointer" }}
                >
                  Đức Ngọc
                </span>
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Facebook cá nhân:{" "}
                <Link
                  style={{ color: "#007bff", textDecoration: "none" }}
                  to={"https://www.facebook.com/n.ducngoc.233"}
                  target="_blank"
                >
                  Đức Ngọc
                </Link>
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Fanpage:{" "}
                <Link
                  style={{ color: "#007bff", textDecoration: "none" }}
                  to={"https://www.facebook.com/kiemthecaocom"}
                  target="_blank"
                >
                  Kiếm Thẻ Cào
                </Link>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "center" }}>
            <strong>
              Hãy chuẩn bị dụng cụ và thể hiện kỹ năng của bạn! Đỉnh cao của
              danh hiệu câu cá đang chờ đợi bạn chinh phục!
            </strong>
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.title = Title.mobile_app + Title.origin;
  }, []);

  useEffect(() => {
    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
        } else {
          notify(false, response.data.message);
        }
      }
    }

    const getAll = async () => {
      await Promise.all([Promise.resolve(getUserInfo())]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  async function checkUsername(username) {
    const response = await axios.post(
      `${ApiLink.domain + "/account/username"}`,
      { username },
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setUsername(username.trim());
      setInfo(null);
    } else {
      setUsername("");
      setInfo(null);
    }
  }

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  const handleViewDetail = (id) => {
    id === show ? setShow(-1) : setShow(id);
  };

  return loading ? (
    <div></div>
  ) : (
    <div className="app_container">
      <UserBoardLayout
        Element={
          <InputUsernameLayout
            checkUsername={checkUsername}
            setUsernameInput={setUsernameInput}
            usernameInput={usernameInput}
          />
        }
        info={info}
        setInfo={setInfo}
        username={username}
        setUsername={setUsername}
        usernameInput={usernameInput}
        setUsernameInput={setUsernameInput}
        checkUsername={checkUsername}
        handleShowNotify={handleSetShowNotify}
        showNotify={showNotify}
      />

      {showNotify && <NotifyLayout />}

      <div className="list_app">
        <div className="list_name">Danh sách các Event diễn ra</div>
        {eventList.map((item, index) => {
          return (
            <Fragment key={index}>
              <div
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src={item.event_logo}
                    alt="image_app"
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      <img
                        className="app_title_icon"
                        alt="app_title_icon"
                        src="https://kiemthecaofree.com/hot.gif"
                      ></img>
                      <span className="app_title_name">
                        {item.event_title_short}
                      </span>
                    </div>
                    <div className="app_price">
                      <span className="one_app_child_title">Tiền thưởng:</span>
                      <span className="one_app_child_content">
                        {item.event_prize}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Thời gian:</span>
                      <span className="one_app_child_content">
                        {item.event_time}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Đối tượng:</span>
                      <span className="one_app_child_content">
                        {item.event_people}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Người đăng:</span>
                      <span className="one_app_child_content">
                        {item.event_admin}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="one_app--bottom">
                  <div className="one_app--bottom-top">
                    <p
                      className="one_app--bottom_step_title"
                      style={{ color: "#17a2b8" }}
                    >
                      Chi tiết sự kiện:
                    </p>
                    <div
                      className="one_app--bottom_step"
                      onClick={() => {
                        handleViewDetail(item.event_id);
                      }}
                    >
                      <img
                        className="icon_down"
                        alt="icon_down"
                        src={
                          show === item.event_id
                            ? "/images/icons/up-arrow.png"
                            : "/images/icons/down-arrow.png"
                        }
                      ></img>
                      <p className="one_app--bottom_step_title">
                        {show === item.event_id
                          ? "Tắt hướng dẫn"
                          : "Xem hướng dẫn"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="one_app--bottom-bottom"
                    style={{
                      display: show === item.event_id ? "block" : "none",
                    }}
                  >
                    {item.event_content}
                  </div>
                </div>
              </div>
              {index === eventList.length - 1 ? (
                <div className="end_list">Đã tới cuối danh sách</div>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </div>
      <AdSenseAd />
      <Footer margin_top={"15px"} />
    </div>
  );
}
